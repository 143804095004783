.map .ol-zoom {
  right: 34px;
  left: auto;
  top: auto;
  /* // bottom: 35px; */
  bottom: 179px;

  /* @media (max-width: 600px) {
    bottom: 209px;
    right: 21px;
  } */
}

@media (max-width: 600px) {
  .map .ol-zoom {
    bottom: 209px;
    right: 21px;
  }
}

.ol-control {
  /* // background-color: unset; */
  padding: 0px;
  /* // @media (max-width: 600px) {
  //   right: 25px;
  // } */
}

.ol-control button {
  background-color: #111111;
  cursor: pointer;
  /* & :hover {
    background-color: #434343;
  };
  & :focus {
    background-color: #111111;
  }; */
}

.ol-control button:hover {
  background-color: #434343;
}

.ol-control button:focus {
  background-color: #111111;
}

.ol-attribution {
  font-size: 12px;
}

/* // For react-pdf */

.react-pdf__Page__canvas {
  margin: 0 auto;
}