.App {
  font-family: sans-serif;
  text-align: center;
}



@font-face {
  font-family: 'Lato';
  src: local('Lato-Regular'), url('./fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url('./fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'BebasNeue-Regular';
  src: local('BebasNeue-Regular'), url('./fonts/BebasNeue-Regular.ttf') format('truetype')
}

html,
body,
#root,
.App {
  font-family: Lato;
  margin: 0;
  height: 100%;
}

.map {
  /*width: 700px;*/
  height: 100%;
}